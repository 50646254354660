var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Fragment',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-btn',{staticClass:"primary",attrs:{"block":""},on:{"click":function($event){$event.stopPropagation();_vm.modalFormulario = true}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-mail ")]),_vm._v("       Enviar correo ")],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-dialog',{attrs:{"persistent":""},model:{value:(_vm.modalFormulario),callback:function ($$v) {_vm.modalFormulario=$$v},expression:"modalFormulario"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('h3',{staticClass:"text-center"},[_vm._v("Enviar correos a proveedores")])]),_c('v-card-text',{staticClass:"pt-6"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-switch',{attrs:{"label":"Notificación específica"},model:{value:(_vm.form.especifica),callback:function ($$v) {_vm.$set(_vm.form, "especifica", $$v)},expression:"form.especifica"}})],1),(_vm.form.especifica)?_c('v-col',[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.listado_acciones,"item-text":"texto","item-value":"accion","clearable":""},model:{value:(_vm.form.accion),callback:function ($$v) {_vm.$set(_vm.form, "accion", $$v)},expression:"form.accion"}})],1):_vm._e()],1),(
                !_vm.form.especifica ||
                _vm.form.accion === 'notificacion_enviar_adjunto' ||
                _vm.form.accion === 'notificacion_enviar_adjunto_multiples'
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"6","md":"2"}},[_c('v-switch',{attrs:{"label":"Notificar a todos los perfiles"},model:{value:(_vm.form.todos),callback:function ($$v) {_vm.$set(_vm.form, "todos", $$v)},expression:"form.todos"}})],1),_c('v-col',[(!_vm.form.todos)?_c('v-autocomplete',{attrs:{"items":_vm.perfiles,"item-text":"nombre","item-value":"id","outlined":"","clearable":"","label":"Seleccione los perfiles a los que notificará","multiple":"","chips":""},model:{value:(_vm.form.perfiles),callback:function ($$v) {_vm.$set(_vm.form, "perfiles", $$v)},expression:"form.perfiles"}}):_vm._e()],1)],1):_vm._e(),(
                _vm.form.especifica &&
                (_vm.form.accion === 'notificacion_enviar_adjunto' ||
                  _vm.form.accion === 'notificacion_enviar_adjunto_multiples' ||
                  _vm.form.accion === 'notificacion_enviar_centro_escolar' ||
                  _vm.form.accion === 'notificacion_proveedor_menos_100') 
              )?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-file-input',{attrs:{"accept":".pdf, .docx, .pptx, .xlsx, .png","outlined":"","required":"","small-chips":"","show-size":"","label":"Favor anexe el documento adjunto","multiple":_vm.form.accion == 'notificacion_enviar_adjunto_multiples' ||
                    _vm.form.accion == 'notificacion_enviar_centro_escolar' ||
                    _vm.form.accion === 'notificacion_proveedor_menos_100'},model:{value:(_vm.form.adjunto),callback:function ($$v) {_vm.$set(_vm.form, "adjunto", $$v)},expression:"form.adjunto"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","clearable":"","label":"Asunto del correo"},model:{value:(_vm.form.asunto),callback:function ($$v) {_vm.$set(_vm.form, "asunto", $$v)},expression:"form.asunto"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('app-rich-text-component',{model:{value:(_vm.form.mensaje),callback:function ($$v) {_vm.$set(_vm.form, "mensaje", $$v)},expression:"form.mensaje"}})],1)],1)],1),_c('v-card-accions',[_c('div',{staticClass:"d-flex justify-end px-4 py-4"},[_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-1",attrs:{"color":"secondary"},on:{"click":function($event){$event.stopPropagation();return _vm.cerrarForm.apply(null, arguments)}}},[_vm._v("Cerrar")]),_c('v-btn',{staticClass:"flex-grow-1 flex-shrink-1 ml-4",attrs:{"color":"primary"},on:{"click":_vm.validarForm}},[_vm._v("Guardar")])],1)])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('ConfirmationDialog',{attrs:{"show":_vm.modalConfirmacion,"title":"Deseas enviar este correo?","message":"Se enviará a los perfiles seleccionados.","btnConfirmar":"ENVIAR"},on:{"close":function($event){_vm.modalConfirmacion = false},"confirm":_vm.enviarCorreo}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }