<template>
  <DashboardPerfil v-if="isProvider" />
  <v-container v-else>
    <v-card class="px-0 py-0">
      <v-card-title class="px-0 py-0">
        <div class="bgCardHeader d-flex align-center justify-center" style="min-height: 250px; width: 100%;">
          <v-img contain class="mt-8 mb-10 mx-auto" src="@/assets/img/comprasal_oficial.png" max-width="350" />
        </div>
      </v-card-title>
      <v-divider />
      <v-card-text class="px-4 pt-0 pb-4" style="min-height: 500px">
        <h6 class="text-h5 mt-4 primary--text font-weight-bold text-uppercase text-center">Bienvenido a tu portal COMPRASAL</h6>
        <PlanificacionMensualPAC 
          v-if="haveRoles([ 'ROLE_TECNICO_DIRECCION_COMPRAS', 'ROLE_UACI', 'ROLE_UACI_TECNICO', 'ROLE_OFICIAL_CUMPLIMIENTO','ROLE_AUDITORIA_GLOBAL'])" 
          class="mt-4"
          hide-header
          :id-institucion="idInstitucionUsuario" 
          :filtros-sticky="false"
        >
          <template v-if="haveRoles(['ROLE_UACI'])" v-slot:prepend-actions="{ disabled, anioFiscal }">
            <CardComponent
              :disabled="disabled" 
              :anio-fiscal="anioFiscal"
            />
          </template>
        </PlanificacionMensualPAC>
    
        <adminActions class="mt-4" />
    
        <ObtenerProcesoContrato 
          v-if="haveRoles(['ROLE_HERRAMIENTA_ELIMINAR_ETAPAS_CONTRATO','ROLE_CM_HERRAMIENTA_CATALOGO_ELECTRONICO']) && !haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_DIRECCION_COMPRAS'])" 
          class="mt-4"
        />
    
        <CMDetalleSolicitudCompra 
          v-if="haveRoles(['ROLE_CM_HERRAMIENTA_CATALOGO_ELECTRONICO']) && !haveRoles(['ROLE_SUPER_ADMIN', 'ROLE_DIRECCION_COMPRAS'])"
          class="mt-4" 
        />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import ConfirmationDialog from "@/components/ConfirmationDialogComponent.vue";
import CardComponent from "@/components/CardComponent.vue";
import adminActions from '@/views/AdminActions/index.vue';
import DashboardPerfil from "./PerfilProveedor/perfilProveedorView.vue";
import store from "../store";
import { mapState, mapMutations } from "vuex";
import ObtenerProcesoContrato from '@/views/DashboardComponents/ObtenerProcesoContrato.vue';
import CMDetalleSolicitudCompra from "./DashboardComponents/CMDetalleSolicitudCompra.vue";
import DataTableComponent from '@/components/DataTableComponent.vue';
import { formatDate } from '@/utils/datetime';
import { mostrarPorcentaje } from '@/utils/data';
import PlanificacionMensualPAC from "@/components/planificacionMensualPAC/PlanificacionMensualPAC.vue";

export default {
  name: "dashboardView",
  components: {
    DashboardPerfil,
    ConfirmationDialog,
    adminActions,
    ObtenerProcesoContrato,
    CMDetalleSolicitudCompra,
    CardComponent,
    DataTableComponent,
    PlanificacionMensualPAC,
  },
  data: () => ({
    isProvider: false,
    anios: [],
    tour: null,
    pacFilters: {
      year: new Date().getFullYear(),
      institucion: null,
    },
    instituciones: [],
    documentos: [],
  }),
  methods: {
    formatDate,
    ...mapMutations("agregarInsumo", ["setGetTour"]),
    mostrarPorcentaje,
    async getTours() {
      const response = await this.services.TourService.getTour();
      this.tour = response.data;
      this.setGetTour(this.tour);
    },
    async getInstitituciones() {
      const response =
        await this.services.Instituciones.getCatalogoInstituciones({
          pagination: false,
        });
      this.instituciones = response.data;
    },
  },
  computed: {
    ...mapState(["selectedUnidad"]),
    idInstitucionUsuario() {
      if (this.haveRoles(['ROLE_DIRECCION_COMPRAS', 'ROLE_TECNICO_DIRECCION_COMPRAS'])) return null;
      
      return this.selectedUnidad?.instituciones?.id;
    },
  },
  mounted() {
    this.getTours();
    this.isProvider = store.state.userInfo.roles.includes("ROLE_FRONT_PROFILE_PROVIDER");
  },
  async created() {
    const { data } = await this.services.Auth.obtenerAniosFiscales();
    this.anios = data;

    if (this.haveRoles(["ROLE_DIRECCION_COMPRAS", "ROLE_TECNICO_DIRECCION_COMPRAS", "ROLE_UACI",, "ROLE_AUDITORIA_GLOBAL"])) this.getInstitituciones();
  },
};
</script>
