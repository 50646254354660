<template>
    <div style="max-width: 100%;">
        <v-btn
            color="success" 
            class="white--text py-2"
            style="max-width: 300px; height: unset !important; min-height: 44px !important; min-width: unset !important; max-width: 100%;" 
            large
            dark
            :disabled="disabled"
            :loading="documentos.isLoading"
            @click.stop="cargarDocumentos"
        >
            <v-icon color="white" class="mr-2">mdi-text-box-check</v-icon>
            <span style="word-break: break-word; white-space: normal;">Consultar documento PAC</span>
        </v-btn>

        <v-dialog v-model="modalVerDocumentoPac" max-width="1200px" persistent>
            <v-card>
                <v-card-title class="px-4 py-4">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;" 
                        class="pt-0"
                        :loading="documentos.isLoading"
                        @click.stop="modalVerDocumentoPac = false" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-4 py-4">
                    <DataTableComponent 
                        :headers="headers"
                        :items="documentos.data" 
                        :show_loading="documentos.isLoading" 
                        :tiene_paginacion="false"
                    >
                        <template v-slot:item.estado="{ item }">
                          <v-chip :color="obtenerEstadoDocumento(item).color" class="white--text py-2 mx-auto"
                            style="max-width: 250px;" label>
                            <div class="d-flex align-center" style="gap: 8px;">
                              <v-icon color="white" size="20px !important">{{ obtenerEstadoDocumento(item).icono
                                }}</v-icon>
                              <span>{{ obtenerEstadoDocumento(item).texto }}</span>
                            </div>
                          </v-chip>
                        </template>
                        <template v-slot:item.numero_proceso="{ item }">
                          {{ item.documento?.numero_proceso ?? '-' }}
                        </template>
                        <template v-slot:item.avance="{ item }">
                          {{
                            item.documento?.numero_proceso > 0
                              ? `${item.procesos_pac} (${mostrarPorcentaje(item.porcentaje_avance)})`
                              : '-'
                          }}
                        </template>
                        <template v-slot:item.aprobacion="{ item }">
                          <div v-if="item.documento?.created_at || item.documento?.aprobado_en || item.documento?.fecha_aprobacion"
                            class="py-4">
                            <v-tooltip v-if="item.documento?.created_at" top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="d-flex align-center justify-center" style="gap: 8px;">
                                  <v-icon size="20px !important">mdi-file-upload</v-icon>
                                  {{ formatDate(item.documento.created_at) }}
                                </div>
                              </template>
                              <span>Fecha de carga</span>
                            </v-tooltip>
              
                            <v-tooltip v-if="item.documento?.aprobado_en" top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="d-flex align-center justify-center mt-4" style="gap: 8px;">
                                  <v-icon size="20px !important">mdi-laptop</v-icon>
                                  {{ formatDate(item.documento.aprobado_en) }}
                                </div>
                              </template>
                              <span>Aprobación en sistema</span>
                            </v-tooltip>
              
                            <v-tooltip v-if="item.documento?.fecha_aprobacion" top>
                              <template v-slot:activator="{ on, attrs }">
                                <div v-on="on" v-bind="attrs" class="d-flex align-center justify-center mt-4" style="gap: 8px;">
                                  <v-icon size="20px !important">mdi-account-check</v-icon>
                                  {{ formatDate(item.documento.fecha_aprobacion) }}
                                </div>
                              </template>
                              <span>Fecha de aprobación</span>
                            </v-tooltip>
                          </div>
                        </template>
                        <template v-slot:item.acciones="{ item }">
                          <div class="d-flex align-center justify-center" style="gap: 16px;">
                            <v-btn :disabled="!item.documento" @click.stop="visualizarDocumento(item)" icon>
                              <v-icon>mdi-eye</v-icon>
                            </v-btn>
                          </div>
                        </template>
                      </DataTableComponent>
                      <PdfModal :is-open.sync="isPdfModalOpen" :source-loadable="pdfLoadable" filename="documento" />
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { 
    createLoadable, 
    createPageable,
    setLoadableResponse, 
    setPageableResponse, 
    toggleLoadable, 
    togglePageable,
} from '@/utils/loadable';
import DataTableComponent from './DataTableComponent.vue';
import { mostrarPorcentaje } from '@/utils/data';
import { formatDate } from '@/utils/datetime';
import { mapState } from "vuex";
import PdfModal from './PdfModal.vue';

export default {
    components: { DataTableComponent, PdfModal },
    props: {
        disabled: { type: Boolean },
        anioFiscal: { type: [Number, String] },
    },
    data: () => ({
        isPdfModalOpen: false,
        documentos: createPageable([], 10),
        isLoading: false,
        pdfLoadable: createLoadable(null),
        modalVerDocumentoPac: false,
    }),
    computed: {
        ...mapState(["selectedUnidad"]),
        registrosTotales() {
            return this.documentos.pagination.total_rows;
        },
        clearData() {
            return this.documentos.data && this.documentos.data.length > 0;
        },
        headers() {
            return [
                { text: 'Institución', value: 'nombre', align: 'center', sortable: false },
                { text: 'Estado', value: 'estado', align: 'center', sortable: false },
                { text: 'Número de procesos', value: 'numero_proceso', align: 'center', sortable: false },
                { text: '% Avance', value: 'avance', align: 'center', sortable: false },
                { text: 'Aprobación', value: 'aprobacion', align: 'center', sortable: false },
                { text: 'Documento', value: 'acciones', align: 'center', sortable: false },
            ];
        },
    },
    methods: {
        formatDate,
        mostrarPorcentaje,
        obtenerEstadoDocumento(institucion) {
            const estados = {
                'pendiente': { texto: 'Documento pendiente', icono: 'mdi-file-document-alert', color: "info" },
                'cargado': { texto: 'Documento cargado', icono: 'mdi-file-upload', color: "primary" },
                'aprobado': { texto: 'Documento aprobado', icono: 'mdi-file-document-check', color: "success" },
            }

            const documento = institucion.documento;

            if (!documento) return estados.pendiente;

            return documento.aprobado ? estados.aprobado : estados.cargado;
        },
        async cargarDocumentos() {
            this.modalVerDocumentoPac = true;
            togglePageable(this.documentos);
            const { data, headers } = await this.services.ReporteriaUCP.cargarDocumentos(
                this.anioFiscal,
                this.selectedUnidad.instituciones.id
            );
            setPageableResponse(this.documentos, data, headers);
        },
        async visualizarDocumento(item) {
            this.isPdfModalOpen = true;
            toggleLoadable(this.pdfLoadable);
            const { data } = await this.services.ReporteriaUCP.visualizarDocumento(item.documento.id)
            setLoadableResponse(this.pdfLoadable, data, { isFile: true });
        },
    },
    watch: {
        anioFiscal: {
            handler(value, prev) {
                if (value === prev) return;

                this.documentos = createPageable([], 10);
            },
        },
    },
};
</script>
<style scoped>
:deep(.v-btn__content) {
    max-width: 100%;
    flex-wrap: wrap;
}
</style>